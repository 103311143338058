import React, { useState } from "react";
import Button from "./button";
import CarrotDown from "./icons/carrot-down";
import CarrotUp from "./icons/carrot-up";

const CourseInfo = ({
  title,
  price,
  duration,
  people,
  description,
  sport,
  type,
  requestOnly,
}) => {
  const [courseOpen, setCourseOpen] = useState(false);

  const handleCourseToggle = () => {
    setCourseOpen(!courseOpen);
  };

  return (
    <div className="bg-info-300 rounded-[10px] p-4">
      <div
        className="flex flex-row justify-between"
        onClick={handleCourseToggle}
        role="button"
      >
        <div>
          <h4>{title}</h4>
        </div>
        <span className="p-2">
          {courseOpen ? <CarrotUp /> : <CarrotDown />}
        </span>
      </div>
      <div className="font-semibold">{price}€</div>
      <div className={`${courseOpen ? "block" : "hidden"}`}>
        <div className="pb-4">
          {duration != null ? duration : ""}
          <div>{people != null ? "Max. " + people + " Pers." : ""}</div>
        </div>
        <p>{description}</p>
        <div className="mt-6 mb-2">
          {requestOnly === true ? (
            <Button
              external={true}
              to="https://docs.google.com/forms/d/e/1FAIpQLSedNmxlkNMQo-NWV1DMDF4Kn7f7eWHdyQMMD04xYPvszyJfWA/viewform"
              title="Buchungsanfrage"
              btntype="primary"
            />
          ) : (
            <Button
              to={`/kurstermine/?sport=${sport || "ALL"}&type=${type || "ALL"}`}
              title="Anmelden"
              btntype="primary"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseInfo;
