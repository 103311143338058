import React from "react";
import Button from "../button";
import Container from "../container";
import FaqSection from "../faqsection";

const SportsLayout = ({
  title,
  image,
  description,
  rental_description,
  courses,
  images,
  sport,
}) => {
  return (
    <div>
      <div className="bg-slate-500">{image != null ? image : ""}</div>
      <div className="bg-secondary rounded-t-3xl relative top-[-1.5rem]">
        <Container>
          <h1>{title}</h1>
          <div>{description}</div>
          <h3 className="pt-8">Kursangebot</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            {courses.map((course, index) => {
              return <div key={index}>{course}</div>;
            })}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h3 className="mt-5">Verleih</h3>
              <p>{rental_description}</p>
              <div className="mt-4 mb-4">
                <Button to="/verleih" btntype="secondary" />
              </div>
            </div>
            <div>
              <h3 className="mt-5">Gutscheine</h3>
              <p>
              Teile deine Freude mit anderen und verschenke ein unvergessliches Surferlebnis auf Langeoog. In unserem Onlineshop findest du Gutscheine für unser gesamtes Kursangebot.
              </p>
              <div className="mt-4 mb-4">
                <Button
                  external="true"
                  to="https://tidens-shop.de"
                  btntype="secondary"
                  description="Tidens Onlineshop"
                />
              </div>
            </div>
          </div>
          <div className="mt-4 mb-2 py-8 grid grid-cols-4 gap-4">
            {images.length > 0 &&
              images.map((image, index) => {
                let colSpanClass = "col-span-2";
                if (image.props.orientation && image.props.orientation === "portrait") colSpanClass = "col-span-2 lg:col-span-1";
                return (
                  <div key={index} className={`flex justify-center ${colSpanClass}`}>
                    {image}
                  </div>
                );
              })}
          </div>
          <h3>Häufige Fragen</h3>
          <FaqSection sport={sport} />
        </Container>
      </div>
    </div>
  );
};

export default SportsLayout;
