import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../../components/layouts/main-layout";
import SportsLayout from "../../components/layouts/sports-layout";
import CourseInfo from "../../components/course-info";
import Seo from "../../components/seo";

const Windsurfen = () => {
  const headerImage = (
    <StaticImage
      src="../../images/heroimages/tidens-windsurfen-header2.jpg"
      alt="Windsurfen lernen bei Tidens Surfhuus"
      width={2048}
      height={680}
      quality={90}
      className="w-full h-[35vw] min-h-[25vw] max-h-[50vh]"
      objectFit="cover"
    />
  );

  const description = (
    <div>
      Windsurfen ist der Klassiker unter den Wassersportarten. Du stehst auf
      einem Board, hältst das Segel direkt vor dir in den Händen und spürst die
      Kraft des Windes in deinem ganzen Körper. Windsurfen ist die älteste
      Windsportart in der Surfwelt und fasziniert durch seine Vielseitigkeit bis
      heute Groß und Klein.
    </div>
  );

  const rentalDescription =
    "Du hast schon ausreichend Kenntnisse, um alleine zu üben? Wenn du bei uns einen Kurs besucht hast (oder gegen Vorlage einer VDWS-Lizenz), kannst du dir bei uns auch Windsurfmaterial ausleihen. Wir verleihen stets aktuelle Segel (auch Profisegel) und Masten von North und Severne sowie Boards von GA und Tabou.";

  const courses = [
    <CourseInfo
      title="Schnupperkurs"
      price="50"
      duration="1,5h"
      people="10"
      description="Der Schnupperkurs ist für alle, die das Windsurfen schon immer einmal ausprobieren wollten. Was ist Windsurfen eigentlich, wie fühlt es sich an und macht es mir Spaß? Komm vorbei und probiere es einfach aus! Unser Schnupperkurs Windsurfen ist auch bei Kindern sehr beliebt."
      sport="WIS"
      type="TRY"
      requestOnly={false}
    />,
    <CourseInfo
      title="VDWS Grundkurs"
      price="229"
      duration="8h (4 Tage) "
      people="8"
      description="Der VDWS-Grundkurs Windsurfen ist für alle geeignet, die noch keine Erfahrung im Windsurfen haben oder die Sportart schon lange nicht mehr ausgeübt haben. In diesem Kurs erlernst du die Grundlagen des Windsurfens. Dazu gehören der Auf- und Abbau sowie der richtige Umgang mit dem Segel, das Fahren auf verschiedenen Kursen, der Richtungswechsel sowie die wichtigsten Knoten. Nach diesem Kurs kannst du bei uns eine VDWS-Lizenz ablegen."
      sport="WIS"
      type="BEG"
      requestOnly={false}
    />,
    <CourseInfo
      title="Aufbau Kurs"
      price="ab 65"
      duration={[
        <div className="pt-2">2h 65€ (1 Tag)</div>,
        <div>4h 125€ (2 Tage)</div>,
        <div>6h 179€ (3 Tage)</div>,
        <div>8h 229€ (4 Tage)</div>,
        <div className="mb-2">10h 275€ (5 Tage)</div>,
      ]}
      people="8"
      description="Wenn du bereits einen Grundkurs absolviert hast oder erste Erfahrungen im Windsurfen hast, bist du im Aufbaukurs richtig. In diesem Kurs werden die Grundlagen weiter ausgebaut, an deiner Technik gearbeitet und neue Manöver erlernt."
      sport="WIS"
      type="ADV"
      requestOnly={false}
    />,
    <CourseInfo
      title="Privatstunde"
      price="99"
      duration="1h"
      people="1"
      description="Du möchtest etwas ganz Bestimmtes lernen, an deiner Power-Halse arbeiten, dich an deinem ersten Freestyle-Manöver versuchen oder bist einfach nur auf der Suche nach intensiver Betreuung? Gerne unterrichten wir dich auch in einer Privatstunde. Bitte beachte, dass wir Privatstunden nur auf Anfrage anbieten können."
      requestOnly={true}
    />,
    <CourseInfo
      title="VDWS Lizenz"
      price="50"
      duration="1h"
      people="8"
      description="Im Anschluss an den Grundkurs kannst du bei uns auch eine VDWS-Lizenz machen. Diese gilt als Nachweis für deine Fähigkeiten im Bereich Windsurfen und wird zum Beispiel für das Ausleihen von Material benötigt. Im Preis sind das Workbook Windsurfen, die Prüfung sowie die Lizenz enthalten."
      requestOnly={true}
    />,
    <CourseInfo
      title="VDWS Junior Lizenz"
      price="35"
      duration="1h"
      people="8"
      description="Auch Kinder können bei uns im Anschluss an den Grundkurs eine VDWS-Lizenz machen. Speziell für Kinder gibt es die Junior-Lizenz mit kindgerechten Lehrheften und Materialien. Diese ist bis zu einem Alter von 14 Jahren gültig und kann anschließend mit einer Theorieprüfung in den offiziellen VDWS-Grundschein überführt werden (25€). Im Preis sind das Workbook Windsurfen Junior, die Prüfung sowie die Junior-Lizenz enthalten."
      requestOnly={true}
    />,
  ];

  const image1 = (
    <StaticImage
      src="../../images/galery/wind/tidens_WIS_galery-1.jpg"
      alt="Windsurfen lernen bei Tidens Surfhuus"
      width={700}
      height={465}
      quality={90}
      objectFit="cover"
      className="w-full aspect-square lg:aspect-auto lg:max-h-[25vw]"
    />
  );

  const image2 = (
    <StaticImage
      src="../../images/galery/wind/tidens_WIS_galery-2.jpg"
      alt="Windsurf Kurse bei Tidens Surfhuus"
      width={700}
      height={465}
      quality={90}
      objectFit="cover"
      className="w-full aspect-square lg:aspect-auto lg:max-h-[25vw]"
    />
  );

  const images = [image1, image2];

  return (
    <Layout>
      <Seo
        title="Windsurf Kurse für Anfänger und Fortgeschrittene"
        description="Du möchtest Windsurfen lernen? Wir bieten Windsurfkurse für Anfänger und Fortgeschrittene auf der Nordseeinsel Langeoog an."
      />
      <SportsLayout
        title="Windsurfen"
        sport="windsurfen"
        image={headerImage}
        description={description}
        rental_description={rentalDescription}
        courses={courses}
        images={images}
      />
    </Layout>
  );
};

export default Windsurfen;
