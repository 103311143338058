import { Link } from 'gatsby';
import React from 'react';
import Arrow from './icons/arrow';


const Button = ({to, btntype, title, external, arrow}) => {

  let buttonClasses = "inline-flex rounded-full pl-5 py-2 border-primary border hover:font-bold ";

  if (arrow === false ) {buttonClasses += "pr-5 "} else {buttonClasses += "pr-3 "};

  switch (btntype) {
    case "primary":
      buttonClasses += "bg-primary text-white ";
      break;
    case "secondary":
      buttonClasses += "bg-secondary text-primary ";
      break;
      case "outlined":
        buttonClasses += "bg-none border-white text-white ";
        break;
    default:
      break;
  }

  if (external === false) {
    return ( 
      <Link to={to} className={buttonClasses}>{title} {arrow === false ? null : (<Arrow color={btntype} className="ml-2"/>)}</Link>
     );
  } else {
    return (
      <a href={to} target="_blank" className={buttonClasses} rel="noreferrer" >{title} {arrow === false ? null : (<Arrow color={btntype} className="ml-2"/>)}</a>
    );
  }

}
 
export default Button;

Button.defaultProps = {
  title: "Mehr erfahren",
  btntype: "primary",
  to: "",
  external: false,
  arrow: true,
  disabled: false
};